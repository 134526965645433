body {
	background-color: $primary-background;
	color: rgba(255, 255, 255, 0.9);
	font-family: arial, "Microsoft JhengHei", "微軟正黑體", sans-serif !important;
	font-size: 1em;
	max-width: 1920px;
	margin: 0 auto;
	// min-width: 360px;
}

a:hover {
	color: $primary;
}

ul {
	li {
		list-style: none;
	}

	a {
		color: #CFCFCF;
	}
}

select {
	background: rgba(15, 15, 15, 0);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: #fafafa;
	position: relative;
	border: $primary 1px solid;
	width: 100%;
	max-width: 276px;
	padding: 8px 16px 8px 8px;
	border-radius: 5px;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	margin-right: 24px;
	background-position: right 0.5em top 50%, 0 0;
	background-size: 16px;
	background-repeat: no-repeat, repeat;
	cursor: pointer;

	option {
		background: rgba(0, 0, 0, 0.885);
	}

	&.select-language {
		padding: 6px 16px 6px 8px;
		color: $textColor;

		@media screen and (max-width: 768px) {
			padding: 4px 16px 4px 8px;
			font-size: .85rem;
		}
	}
}


.invalid-feedback {
	color: $invalid;
	margin-bottom: 1.2rem;
}

.valid-feedback {
	color: $valid;
	margin-bottom: 1.2rem;
}

.btn-outline-warning {
	color: $primary;
	border-color: $primary;
	background-color: rgba(0, 0, 0, 0);

	&:hover {
		background-color: $primary  !important;
		border-color: $primary  !important;
		color: $hoverTextColor  !important;
	}

	&:focus {
		background-color: transparent;
		color: $primary;
		box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5);
	}
}

.color-primary {
	color: $primary;
}

.color-primary-dark {
	color: $primary-dark;
}

.bg-1st {
	background-color: $primary-background;
}

.bg-2nd {
	background-color: $secondary-background;
}

.iconImg {
	width: 1.3rem;
}

.btn_primary {
	border: none;
	background-color: $primary;
	padding: 7px 15px;
	color: rgba(15, 15, 15, 1);
	border-radius: 4px;
	border: 1px solid $primary;

	&:hover {
		background-color: transparent !important;
		color: $primary;
		border: 1px solid $primary  !important;
	}

	&:focus {
		background-color: transparent;
		color: $primary;
		box-shadow: 0 0 0 .25rem rgba(255, 193, 7, .5);
	}

	&:disabled {
		background-color: transparent !important;
		border: 1px solid $textColor  !important;
		color: #aaa;
	}
}

.error-Notification {
	background-color: $secondary-background;
	border: 1px solid $invalid;
	border-radius: 10px;

	// color: $invalid  !important;
	span {
		color: $invalid ;
	}
}

.alert-Notification {
	background-color: $secondary-background;
	border: 1px solid $valid;
	border-radius: 10px;

	// color: $invalid  !important;
	span {
		color: $valid ;
	}
}

.text-color {
	color: $textColor;
}

.error-Msg {
	color: $invalid;
}

.swiper-button-prev,
.swiper-button-next {
	color: $primary  !important;
	border-radius: 100%;
	background-color: #201A0B4A;
	width: 43px !important;
	border: 2px solid #E7C6737D;

	&::after {
		font-size: 1.2rem !important;
		font-weight: 900 !important;
	}
}