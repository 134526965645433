.member {
	min-height: calc(100vh - 63.2px);
	background-color: rgb(63, 63, 63);

	label {
		display: block;
	}

	input[type="text"],
	input[type="password"] {
		width: 100%;
		height: 48px;
		padding: 13px 16px;
		background-color: rgb(38, 38, 38);
		border: 1px solid rgb(38, 38, 38);
		border-radius: 16px;
		color: rgb(255, 255, 255);
		outline: none;
		caret-color: $primary;
		box-sizing: border-box;

		&:disabled {
			background-color: #80808085;
			color: #999;
		}
	}

	input[type="submit"] {
		width: 100px;
		height: 48px;
		margin-top: 16px;
		background-color: $primary;
		border: 1px solid $primary;
		border-radius: 16px;
		color: rgb(255, 255, 255);
		outline: none;
		cursor: pointer;
		box-sizing: border-box;

		&:hover {
			background-color: lighten($primary, 10%);
		}
	}

	p {
		padding: 0;
		margin: 0;
	}

	&-form-error {
		color: red;
		font-size: 14px;
		margin-top: 5px;
	}

	&-sidebar {
		min-width: 280px;
	}

	&-sidebar-user,
	&-sidebar-nav {
		border-radius: 16px;
		background-color: rgb(43, 43, 43);
	}

	&-sidebar-user {
		font-size: 14px;

		&-avatar {
			width: 120px;
			height: 120px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	&-sidebar-nav {
		font-size: 18px;

		a {
			display: block;
			line-height: 48px;
			height: 48px;
			padding-left: 10px;
			color: #fff;

			&.active {
				color: $primary;
				border-left: 8px solid $primary;
			}
		}
	}
}

.memberOrder {
	.orderHead {
		display: grid;
		grid-template-columns: .5fr 1.3fr 1fr 1.7fr .5fr;
		padding: 5px 0 5px 1rem;
		background-color: $primary;
		color: $primary-background;
		border-radius: 2px;

		@media screen and (max-width: 768px) {
			white-space: nowrap;
			grid-template-columns: 1.5fr 2fr 2.5fr 1fr;
			padding: 5px 0 5px .5rem;
			font-size: .8rem;
		}
	}

	.orderContentWrap {
		padding-left: 0px;

		.orderContentListWrap {
			cursor: pointer;
			padding: .8rem 0 .8rem 1rem;
			display: grid;
			grid-template-columns: .5fr 1.3fr 1fr 1.7fr .5fr;
			border: 1px solid #9e9e9ed9;
			margin-bottom: .6rem;
			border-radius: 2px;

			@media screen and (max-width: 768px) {
				grid-template-columns: 1fr 4fr 1fr 1.5fr;
				overflow-x: auto;
				padding: .8rem 0 .8rem .3rem;
				font-size: .8rem;
			}

			li {

				&:nth-of-type(2),
				&:nth-of-type(4) {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				&:nth-of-type(5) {
					text-align: center;
					background-color: $primary;
					color: $primary-background;
					border-radius: 3px;
					margin-right: 8px;
					font-size: .86rem;
					padding: 3px 0;

					@media screen and (max-width: 768px) {
						display: none;
					}
				}

				@media screen and (max-width: 768px) {
					white-space: nowrap;
					border-left: 1px solid #9e9e9ed9;
					padding: 0 4px;

					&:nth-of-type(1) {
						border-left: unset
					}
				}
			}

			button {
				text-align: center;
				background-color: $primary;
				color: $primary-background;
				border-radius: 3px;
				margin-right: 8px;
				font-size: .86rem;
			}
		}

	}
}