.backStageSider {
  // display: none;
  // height: 100vh;
  background-color: $primary-background  !important;

  .menu-bar {
    background-color: $primary-background  !important;

    .ant-menu-item {
      // margin: 30px 0;
      font-size: .9rem;
      color: $textColor;

      &.ant-menu-item-disabled {
        background-color: $primary-background;
        color: $hoverTextColor  !important;

        &:hover {
          color: $hoverTextColor  !important;
          background-color: $primary-background  !important;
        }
      }
    }

    .ant-menu-item-selected,
    .ant-menu-item:hover {
      color: $transparent-background  !important;
      background-color: $primary  !important
    }


  }

  .triger-btn {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10px;
    left: 10px;
    color: $textColor;

    &:hover {
      color: $primary  !important;
    }
  }

}


.backStageContent {
  background-color: $primary-background;
  color: $textColor;
  padding: 10px 20px;

  @media screen and (max-width: 768px) {
    padding: 10px 10px;

  }

  .card {
    // display: none;
    color: $textColor;
    border: 2px solid $hoverTextColor;
    background-color: $primary-background ;
    // border-radius: 10px;


    .ant-card-body {
      // background-color: $primary-background;
      padding: 0px;
    }

    .StatisticContainer {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .Statistic {
      width: 100%;
      // background: #fcc;

      .ant-statistic-title {
        color: $textColor ;
        padding-top: 4px;
        margin-bottom: 0px;
        font-size: .8rem;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 768px) {
          padding-top: 2px;
        }
      }

      .ant-statistic-content {
        font-size: 1.3rem;

        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }

        color: $textColor ;
      }

      .ant-skeleton-title {
        background: $secondary-background
      }

    }
  }

  .chartCard {
    width: 100%;
    background: $third-background;
    border: 0px solid $third-background;

    .ant-card-body {
      padding: 20px 0 15px 15px;

      @media screen and (max-width: 768px) {
        padding: 15px 0 5px 8px;

      }
    }

  }

  .divider {
    // display: none;
    color: $primary;

    &::after,
    &::before {
      border: 1px solid $primary;
    }
  }

  .memberTable {
    .ant-table-container {
      scrollbar-color: rgba(0, 0, 0, 0.42) rgba(0, 0, 0, 0.74);
    }

    .ant-table-thead tr th {
      background-color: $primary ;

      &::before {
        background-color: $primary-dark  !important;
      }
    }

    .ant-table-tbody {
      color: $textColor;
      background-color: $secondary-background;

      .ant-table-cell-fix-right,
      .ant-table-cell-fix-right-first,
      .ant-table-cell-fix-left,
      .ant-table-cell-fix-left-first {
        background-color: $secondary-background;

      }

      .ant-table-cell-row-hover {
        background: $hoverTextColor ;
      }

    }

    .ant-table-cell {
      // background-color: #fcc;
      padding: 12px 16px;
      border-bottom: 2px solid $hoverTextColor;

      @media screen and (max-width: 768px) {
        padding: 8px 16px;

      }
    }

    .ant-pagination-item {
      color: $textColor;
      border: 1px solid $primary;
      background-color: $primary-background;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      color: $primary;
      /* 设置你想要的颜色 */
    }

    .ant-table-column-has-sorters {
      &:hover {
        background-color: $primary  !important;
      }

    }

    .ant-table-column-sort {
      background-color: rgba(255, 255, 255, 0);

    }

  }

  .List {
    // background-color: $primary-background;
    color: $textColor  !important;
    margin: 0 20px;

    .custom-skeleton {
      .ant-skeleton-content {
        background-color: #0F0F0F;
      }

      .ant-skeleton-avatar {
        background-color: rgba(255, 255, 255, 0.25); // 調整avatar的背景顏色
      }

      .ant-skeleton-title,
      .ant-skeleton-paragraph>li {
        background-color: rgba(255, 255, 255, 0.25); // 調整其他加載特效的背景顏色
      }
    }

    .ant-list-item-meta {
      align-items: center;
    }

    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      color: $textColor  !important
    }
  }
}

.editOrderForm {
  input {
    // display: none;
    font-size: 12px;
    padding: 4px 8px;
    // line-height: 1;
    color: $textColor  !important;
    background-color: $secondary-background  !important;

    &:hover {
      background-color: $secondary-background ;
      color: $textColor ;
    }

    &.ant-input-outlined {
      border-color: $primary;
      // background: $transparent-background  !important;
    }

    &.ant-input-outlined.ant-input-status-error {
      background-color: $secondary-background ;
    }

    &:disabled {
      border: 0px solid $hoverTextColor;
      color: rgb(152, 151, 151);
      background-color: $hoverTextColor;
    }
  }

  #quantity {
    // display: none;
  }
}

.editModal,
.createNewModal,
.confirmModal {
  color: $textColor;

  .ant-modal-header {
    background-color: $secondary-background;

    .ant-modal-title {
      color: $textColor;

    }
  }

  .ant-modal-content {
    background-color: $secondary-background;
  }

  .editMemberForm {
    label {
      color: $textColor ;
    }

    .ant-select-selector {
      background-color: $secondary-background ;
      color: $textColor ;
    }

    .ant-picker {
      background-color: $secondary-background ;

      .ant-picker-suffix {
        color: $primary
      }
    }

    .ant-input-affix-wrapper {
      background-color: $secondary-background ;
      color: $textColor ;

      &.ant-input-outlined {
        background-color: $secondary-background ;
      }
    }


    input {
      color: $textColor ;
      background-color: $secondary-background ;

      &.ant-input-outlined.ant-input-status-error {
        background-color: $secondary-background ;
      }

      &:disabled {
        border: 0px solid $hoverTextColor;
        color: rgb(152, 151, 151);
        background-color: $hoverTextColor;
      }
    }
  }

  .ant-modal-close-x {
    color: $textColor;
  }

  .anticon.ant-input-password-icon {
    color: $textColor ;

    &:hover {
      color: $textColor ;
    }
  }

  .ant-modal-confirm-title {
    color: $textColor ;
    // background-color: $primary-background;
  }

}